import _taggedTemplateLiteral from "/app/example/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  margin-left: 10px;\n\n  a {\n    color: ", ";\n    text-decoration: none;\n\n    display: flex;\n    align-items: center;\n    height: 50px;\n    padding: 0 10px;\n    white-space: nowrap;\n\n    transition: background-color 0.15s ease-in-out;\n\n    :hover, :focus {\n      background-color: ", ";\n    }\n  }\n\n  transition: max-width 0.1s ease-in-out, margin-left 0.1s ease-in-out, opacity 0.5s ease-in-out;\n  max-width: 100%;\n  opacity: 1;\n  flex: 1000;\n  &.isSearchOpen {\n    overflow: hidden;\n    flex: 1;\n    opacity: 0;\n    max-width: 0;\n    margin-left: 0;\n  }\n\n  &.isMobile {\n    padding-bottom: 50px;\n    overflow: visible;\n    flex-direction: column;\n    align-items: flex-start;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  box-sizing: border-box;\n  height: 50px;\n\n  button, a {\n    all: unset;\n    color: ", "\n    fill: ", "\n    background-color: ", "\n    display: flex;\n    align-items: center;\n\n    padding: 0 10px;\n    margin: 0 1px;\n    width: max-content;\n    height: 100%;\n\n    transition: scale 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n\n    :hover {\n      cursor: pointer;\n    }\n\n    :hover, :focus {\n      scale: 1.05;\n      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);\n    }\n  }\n\n  transition: flex 0.5s ease-in-out;\n  flex: 1;\n  &.search {\n    flex: 1000;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  color: ", ";\n  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px;\n\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  z-index: 900;\n\n  div {\n    margin: 0 auto;\n    max-width: 1240px;\n    display: flex;\n    align-items: flex-end;\n  }\n\n  transition: top ", "s;\n  &.isMobile {\n    top: -", "px;\n  }\n  &.isMobile.isMenuOpen {\n    top: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React, { Fragment, useState } from "react";
import { render } from "react-dom";
import classNames from "classnames";
import styled, { ThemeProvider } from "styled-components";
import Delta from './Delta';
var Bar = styled.div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.primary.main;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.primary.contrastText;
}, function (_ref3) {
  var menuClicks = _ref3.menuClicks;
  return menuClicks == 0 ? 0 : 0.2;
}, function (_ref4) {
  var links = _ref4.links;
  return links * 50;
});
var Buttons = styled.div(_templateObject2(), function (_ref5) {
  var theme = _ref5.theme;
  return theme.primary.contrastText;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.primary.contrastText;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.primary.light;
});
var List = styled.div(_templateObject3(), function (_ref8) {
  var theme = _ref8.theme;
  return theme.primary.contrastText;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.primary.light;
});

var TopBar = function TopBar(_ref10) {
  var isMobile = _ref10.isMobile,
      isSearchOpen = _ref10.isSearchOpen,
      toggleSearch = _ref10.toggleSearch,
      setSearchString = _ref10.setSearchString,
      menuClicks = _ref10.menuClicks,
      toggleMenu = _ref10.toggleMenu,
      config = _ref10.config,
      barRef = _ref10.barRef,
      searchbar = _ref10.children;
  return React.createElement(Bar, {
    className: classNames({
      isMobile: isMobile,
      isMenuOpen: menuClicks % 2 == 1
    }),
    links: config.links.length,
    menuClicks: menuClicks
  }, React.createElement("div", {
    ref: barRef
  }, React.createElement(Buttons, {
    style: {
      flex: 0
    }
  }, React.createElement("a", {
    href: "/"
  }, React.createElement(Delta, null))), React.createElement(List, {
    className: classNames({
      isMobile: isMobile,
      isSearchOpen: isSearchOpen
    })
  }, (config.links || []).map(function (link) {
    return React.isValidElement(link) ? React.cloneElement(link, {
      key: link.to,
      onClick: function onClick(e) {
        return toggleMenu();
      },
      children: link.props.children.toUpperCase()
    }) : React.createElement("a", {
      href: link.href,
      key: link.href
    }, link.barStr || link.str.toUpperCase());
  })), React.createElement(Buttons, {
    className: classNames({
      isSearchOpen: isSearchOpen
    })
  }, React.createElement("button", {
    onClick: function onClick(e) {
      return toggleSearch();
    }
  }, React.createElement("i", {
    className: "fas fa-search"
  })), searchbar, isMobile && React.createElement("button", {
    onClick: function onClick(e) {
      return toggleMenu();
    }
  }, React.createElement("i", {
    className: "fas fa-bars"
  })), config.login_href && config.login_text && React.createElement("a", {
    href: config.login_href
  }, config.login_text))));
};

export default TopBar;